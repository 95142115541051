<template>
  <div class="pair-container">
    <TeamMember v-for="member in pair" v-bind:key="member.id" :member="member" />
  </div>
</template>

<script>
import TeamMember from "./TeamMember.vue"

export default {
  name: "TeamPair",
  components: {
    TeamMember
  },
  props: {
    pair: {
      type: Array,
      required: true
    }
  }
}
</script>
