<template>
  <section>

    <ul class="unstyled-list">
      <li
        v-for="image, key in images"
        v-show="key == activeIndex"
        @click="upIndex"
        class="pointer"
        v-touch:swipe="swipeHandler"
      >
        <ImageContainer
          :url="image.medium"
          :alttext="image.title"
          :caption="image.bildnachweis"
          :is-plan="key >= numRegularPics"
        />
      </li>
    </ul>

    <p class="image-counter">{{ imageCount }}/{{ imageNumber }}</p>

  </section>
</template>

<script>
import ImageContainer from "../media/ImageContainer.vue"
import store, { hideTitle, isMobile, isTitleVisible } from "../../store"

export default {
  name: "ProjectImages",
  components: { ImageContainer },
  props: {
    images: {
      type: Array,
      required: true
    },
    numPlans: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  computed: {
    imageCount() {
      // pagina left hand
      return this.activeIndex + 1
    },
    imageNumber() {
      // pagina right hand
      return this.images.length
    },
    numRegularPics() {
      // this is needed to determine if the class "plan"
      // should be added to an img. in order to do this,
      // the prop "numPlans" comes from Project.
      if (this.numPlans) {
        return this.images.length - this.numPlans
      } else {
        return this.images.length;
      }
    }
  },
  methods: {
    upIndex() {
      const numberOfIndexes = this.images.length;
      let newIndex = (this.activeIndex + 1) % numberOfIndexes
      this.activeIndex = newIndex
      // emit event, parent will set bgColor if payload = 0
      this.$emit('image-switched', newIndex);

      // and now the bad stuff
      // hide title from second image on
      if (isMobile() && isTitleVisible()) {
        hideTitle()
      }
    },
    downIndex() {
      const numberOfIndexes = this.images.length;
      let newIndex = ((this.activeIndex - 1) + numberOfIndexes) % numberOfIndexes
      this.activeIndex = newIndex
      // emit event, parent will set bgColor if payload = 0
      this.$emit('image-switched', newIndex);

      // and now the bad stuff
      // hide title from second image on
      if (isMobile() && isTitleVisible()) {
        hideTitle()
      }
    },
    swipeHandler(direction) {
      console.log(this)
      console.log(direction)
      if(direction == 'left') {
        this.upIndex()
      } else {
        this.downIndex()
      }
    }
  }
}
</script>
