<template>
  <h1 class="main-heading upper" v-show="titleVisible"><a class="un-deco" href="/">{{ title }}</a></h1>
</template>

<script>
import store, { isTitleVisible } from "../store"

export default {
  name: "EkaHeading",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    titleVisible() {
      // this is crazy
      return isTitleVisible() ? true : false
    },
    bar() {
      return true
    }
  }
}
</script>
