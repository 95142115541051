<template>
  <svg class="close pointer" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 25L25 1" stroke="#000000" stroke-width="2"/>
    <path d="M1 1L25 25" stroke="#000009" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "Close"
}
</script>
