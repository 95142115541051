// this is not vuex, but similar
// it serves mainly to keep track of the viewport,
// i.e. window.innerWidth, and -height.
// can be used in components by importing.
// Most importantly in project/Project, project/Projectstudies,
// and project/Competition
// Docs:
// https://medium.com/better-programming/how-to-manage-vues-state-with-vue-observable-25988a88938b
// https://vuejs.org/v2/api/#Vue-observable

import Vue from 'vue'

const state = Vue.observable({
  viewport: {
    width: 0,
    height: 0
  },
  titleVisible: true
});

export const setViewport = () => {
  state.viewport.width = window.innerWidth
  state.viewport.height = window.innerHeight
};

export const hideTitle = () => {
  // this is really bad. until here
  // it all worked just great.
  // this needs to be triggered by all kinds of stuff
  state.titleVisible = false
}

export const isMobile = () => {
  return (state.viewport.width < 770) ? true : false
}

export const isTitleVisible = () => {
  // returns true if desktop viewport
  // otherwise state.titleVisible
  return (state.viewport.width < 770) ? state.titleVisible : true
};

export default state
