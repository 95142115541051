<template>
  <figure>

    <img
      :data-src="url"
      :class="isPlan ? 'plan' : 'regular-image'"
      alt="alttext">

    <!--
      data-src instead of src for
      lazy loading in fullpage.js
    -->

    <ficaption v-if="caption">{{ caption }}</ficaption>

  </figure>
</template>

<script>
export default {
  name: "ImageContainer",
  props: {
    url: {
      type: String,
      required: true
    },
    alttext: {
      type: String,
      required: false
    },
    caption: {
      type: String,
      required: false
    },
    isPlan: {
      type: Boolean,
      required: false
    }
  }
}
</script>
