<template>

  <div class="map-and-contact-container generic-two-col-grid">

    <section class="map-section generic-two-col-grid-section two-col-grid-left">
      <div id="map" class="map"></div>
    </section>

    <section class="contact-section generic-two-col-grid-section two-col-grid-right">

      <article class="text-container">
        <h1 class="upper">{{ contact.title }}</h1>

        <div class="contact-content right-hand-content">
          <p class="address-title upper">{{ contact.name }}</p>
          <p class="address">{{ contact.byline }}<br>
            {{ contact.street }}<br>
            {{ contact.zip }}</p>
          <p class="phone-fax-mail"><a class="un-deco" :href="`tel:${contact.phoneUrl}`">{{ contact.phone }}</a><br>
          <a class="un-deco" :href="`mailto:${contact.email}`">{{ contact.email }}</a></p>
        </div>
      </article>

    </section>

  </div>

</template>

<script>
import store, { isMobile, hideTitle } from "../../store"

export default {
  name: "Contact",
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      markerColor: '#E7BDFF',
      coords: [2681637.500, 1245922.375],
      zoomLevel: isMobile ? 15 : 2
    }
  },
  methods: {
    initiateMap() {
      // 'ga' comes from script tag
      // swiss topo has no npm package yet
      const mapLayer = ga.layer.create('ch.swisstopo.pixelkarte-grau')

      // create marker
      const markerFeature = new ol.Feature({
        geometry: new ol.geom.Point(this.coords)
      });
      var svg = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle fill="${this.markerColor}" cx="12" cy="12" r="12"/></svg>`;

      const iconStyle = new ol.style.Style({
        image: new ol.style.Icon(({
          anchor: [12, 12],
          anchorXUnits: 'pixels',
          anchorYUnits: 'pixels',
          imgSize: [24, 24],
          opacity: 1,
          src: 'data:image/svg+xml;utf8,' + escape(svg)
        })),
      });
      markerFeature.setStyle(iconStyle)

      const markerSource = new ol.source.Vector({
        features: [markerFeature]
      })

      const markerLayer = new ol.layer.Vector({
        source: markerSource,
        name: 'Marker'
      })

      // create map with 2 layers
      const map = new ga.Map({
        target: "map",
        layers: [mapLayer, markerLayer],
        logo: false,
        interactions: ol.interaction.defaults({mouseWheelZoom:false}),
        view: new ol.View({
          center: this.coords,
          resolution: this.zoomLevel,
        })
      });
    },
  },
  async mounted() {
    await this.initiateMap();
  }
}
</script>
