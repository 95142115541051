import Vue from "vue";
import Components from "./components";
// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
import Vue2TouchEvents from 'vue2-touch-events'

import "./sass/reset.scss";
import "./sass/fonts.scss";
import "./sass/general.scss";
import "./sass/fullpage.scss";

import store, { setViewport } from "./store.js"

Vue.config.productionTip = false;

Vue.use(VueFullPage)
Vue.use(Vue2TouchEvents)

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  created() {
    // initialize store.viewport
    setViewport()

    // window.addEventListener('resize', setViewport)
    window.addEventListener('resize', setViewport)

  }
});
