<template>
  <section class="project-list impressum">
    <div class="sub-nav-ui" @click="$emit('close-subnav')">
      <Close />
    </div>

    <div class="impressum-title">{{ impressumcontent.title }}</div>
    <div class="impressum-body" v-html="impressumcontent.text">
    </div>

  </section>
</template>

<script>
import Close from "../ui/Close.vue"

export default {
  name: "Impressum",
  components: {
    Close
  },
  props: {
    impressumcontent: {
      type: Object,
      required: true
    }
  },
  methods: {
  },
  mounted() {
    console.log(this)
  }
}
</script>
