<template>
  <article class="team-member upper">

    <figure class="foto pointer" v-if="member.foto">
      <!--
        not using media/ImageContainer component,
        because that uses the data-src attribute
        instead of src.

        class "pointer" is not ok, because not the figure
        is reactive, but it’s container, but so what.
      -->
      <img :src="member.foto.medium" :alt="member.name">
      <figcaption v-if="member.foto.bildnachweis">{{ member.foto.bildnachweis }}</figcaption>
    </figure>

    <div class="infos">
    <p v-if="member.empty"><!-- odd number empty element 😅 --></p>

      <h1 class="name" v-else>{{ member.name }}</h1>

      <p class="role" v-if="member.role">{{ member.role }}</p>
      <p class="jobtitle" v-if="member.jobtitle">{{ member.jobtitle }}</p>
      <p class="email" v-if="member.phone"><a :href="`mailto:${member.email}`" @click.stop="" class="un-deco">{{ member.email }}</a></p>
      <p class="phone" v-if="member.phone"><a :href="`tel:${member.phoneUrl}`" @click.stop="" class="un-deco">{{ member.phone }}</a></p>
    </div>

  </article>
</template>

<script>
export default {
  name: "TeamMember",
  props: {
    member: {
      type: Object,
      required: true
    }
  },
}
</script>
