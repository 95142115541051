<template>
  <svg class="hamburger pointer" viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H37" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
    <path d="M1 12H37" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
    <path d="M1 24H37" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "Hamburger"
}
</script>
