<template>

  <full-page ref="fullpage" :options="options" id="fullpage">

    <!-- On Mobile «Büro» takes up a single fullpage section 
      This is clearly a design mistake, but there have been so many
      exceptions for mobile that it doesn’t matter anymore.
    -->
    <!-- About/Büro -->
    <div class="section" v-show="isMobile">
      <div class="team-and-about-container generic-two-col-grid">
        <section class="about-section generic-two-col-grid-section two-col-grid-right">
          <div class="buero-sections-background about-section-background"></div>
          <article class="text-container">
            <h1 class="upper">{{ buero.about.title }}</h1>
            <div class="about-text right-hand-content" v-html="buero.about.text">
            </div>
          </article>
        </section>
      </div>
    </div>

    <!-- Team and About/Büro -->
    <div v-if="buero.team && buero.about" class="section">
      <div class="buero-sections-background about-section-background" v-show="!isMobile"></div>
      <h1 v-show="isMobile" class="mobile-team-title upper">Team</h1>
      <TeamAbout :team="buero.team" :about="buero.about" />
    </div>

    <!-- Map and Contact -->
    <div v-if="buero.contact" class="section">
      <div class="buero-sections-background contact-section-background"></div>
      <MapContact :contact="buero.contact" />
    </div>

  </full-page>

</template>

<script>
import TeamAbout from "./buero/TeamAbout.vue"
import MapContact from "./buero/MapContact.vue"
import store, { isMobile, hideTitle } from "../store"

export default {
  name: "Buero",
  components: {
    TeamAbout,
    MapContact
  },
  props: {
    buero: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        licenseKey: '85DEFA44-76C84CD0-90A5D86C-92F9F88C',
        anchors: isMobile ? ['unternehmen', 'team', 'kontakt'] : ['unternehmen', 'kontakt']
      }
    }
  },
  // anchors are a SEO nightmare, but what are you going to do?
  computed: {
    isMobile() {
      return isMobile()
    }
  },
  created() {
    // hide page title on mobile
    if (isMobile()) {
      hideTitle()
    }
  }
}
</script>
