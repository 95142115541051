<template>
  <article class="project">

    <h1 v-show="!isTitleVisible || !isMobile"  class="project-title upper">{{ project.title }}, {{ project.location }}</h1>
    <!-- one exception: project title not visible if on mobile && and first image of project visible -->
    <!-- this is not very sensible but what are you going to do? -->
    <!-- pseudo code: is it the topmost project? is the first pic. visible? -->

    <!-- section.project-plans -->
    <ProjectImages
      v-show="!isMobile"
      :images="project.plans"
      :num-plans="project.plans.length"
      class="project-plans project-left-col" />

    <!-- section.project-images -->
    <!-- gets events from component when image switched
    <!-- not visible when description active -->
    <ProjectImages
      class="project-images project-right-col"
      v-show="!isMobile && !descriptionActive"
      :images="project.images"
      @image-switched="handleImageEvent" />

    <!-- section.project-plan-combo replace preceeding two components, on mobile viewports-->
    <!-- gets events from component when image switched
    <!-- not visible when description active -->
    <!-- is it possible to know whicht of theses are plans? in order to set grey background 💩 -->
    <!-- by count: after pics come plans, by url: contains "plaene" -->
    <ProjectImages
      class="project-images-plans-combo"
      v-show="isMobile && !descriptionActive"
      :images="imagePlanCombo"
      @image-switched="handleImageEvent"
      :num-plans="project.plans.length"
    />

    <!-- section.project-description -->
    <!-- gets events from component when description toggled-->
    <ProjectDescription
      @description-toggled="handleDescriptionEvent"
      :is-project="true"
      :date="project.date"
      :type="project.type"
      :description="project.description"
      :prizes="project.prizes"
      :bg-value="project.bgColor"
      class="project-description project-right-col" />

    <!-- section.optional-background -->
    <section
    class="optional-background"
    :class="(firstImgVisible || descriptionActive) ? 'filled' : 'transparent'"
    :style="`background: ${project.bgColor}`"></section>

  </article>
</template>

<script>
import ProjectImages from "./ProjectImages.vue"
import ProjectDescription from "./ProjectDescription.vue"
import store, { isMobile, isTitleVisible, hideTitle } from "../../store"

export default {
  name: "Project",
  components: {
    ProjectImages,
    ProjectDescription
  },
  props: {
    project: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      descriptionActive: false,
      firstImgVisible: true
    }
  },
  computed: {
    isTitleVisible() {
      return isTitleVisible()
    },
    isMobile() {
      return isMobile()
    },
    imagePlanCombo() {
      return [...this.project.images, ...this.project.plans]
    },
  },
  methods: {
    handleDescriptionEvent(payload) {
      if (payload === "off") { // description closed
        this.descriptionActive = false
      } else {
        this.descriptionActive = true

        // the bad stuff:
        // when the description of the first project is toggled
        // after the first page load, the page tile needs to go away
        if (isMobile() && isTitleVisible()) {
          hideTitle()
        }
      }
    },
    handleImageEvent(payload) {
      if (payload === 0) { // first image visible
        this.firstImgVisible = true
      } else {
        this.firstImgVisible = false
      }
    }
  }
}
</script>
