<template>
  <section class="project-list">
    <div class="sub-nav-ui" @click="$emit('close-subnav')">
      <Close />
    </div>

    <ul class="unstyled-list">
      <li v-for="projectType, key in projectList">
        <h1 class="category-title upper">{{ projectType.name }}</h1>
        <ul class="unstyled-list">
          <li v-for="entry, key in projectType.entries">
          <a
            class="un-deco"
            :href="`${projectType.slug ? '/' + projectType.slug : ''}/#${entry.slug}`"
            @click="$emit('close-nav')">{{ entry.title }}{{ projectLocation(entry.projectLocation) }}</a>
          </li>
        </ul>
      </li>
    </ul>

  </section>
</template>

<script>
import Close from "../ui/Close.vue"

export default {
  name: "ProjectLinkList",
  components: {
    Close
  },
  props: {
    projectList: {
      type: Array,
      required: true
    }
  },
  methods: {
    projectLocation(location) {
      return location ? ', ' + location : ''
    }
  },
  mounted() {
    console.log(this)
  }
}
</script>
