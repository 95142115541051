<template>

  <full-page ref="fullpage" :options="options" id="fullpage">

    <div v-for="project, key in projects" class="section">
      <Project :project="project" />
    </div>

  </full-page>

</template>

<script>
import Project from "./project/Project.vue"
import store, { hideTitle, isMobile } from "../store"

export default {
  name: "ProjectList",
  components: { Project },
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        licenseKey: '85DEFA44-76C84CD0-90A5D86C-92F9F88C',
        anchors: null,
        normalScrollElements: '.description-content, .nav-content, .project-list',
        onLeave: this.onLeave
      }
    }
  },
  methods: {
    onLeave (origin, destination, direction) {
      // the good old bad stuff …
      // there is no scroll event in fullpage
      // this seems to be the way to hide the title
      if (isMobile() && store.titleVisible) {
        hideTitle()
      }
    }
  },
  created() {
    // generate array of slugs for the project-sections
    // this array passed to the anchors property of
    // the fullpage.js options.
    this.options.anchors = this.projects.map((project) => {
      return project.slug
    })
  }
}
</script>
