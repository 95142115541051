<template>

  <div class="team-and-about-container generic-two-col-grid">

    <section class="team-section generic-two-col-grid-section two-col-grid-left">

      <!-- on mobile one image is shown at a time -->
      <ul v-if="isMobile" class="unstyled-list" @click="nextTeamSingle"
        v-touch:swipe="nextTeamSingle"
      >
        <li v-for="member, key in team">
          <TeamMember
            v-show="key === activeTeamSingle"
            :member="member" />
        </li>
      </ul>
      <p v-if="isMobile" class="team-single-counter">{{ activeTeamSingle + 1 }}/{{ team.length }}</p>


      <!-- on desktop two images are shown at once -->
      <ul v-if="!isMobile" class="unstyled-list" @click="nextTeamPair">
        <li v-for="teamPair, key in teamPairs">
          <TeamPair
            v-show="key === activeTeamPair"
            :pair="teamPair" />
        </li>
      </ul>
      <p v-if="!isMobile" class="team-pairs-counter">{{ activeTeamPair + 1 }}/{{ teamPairsLength }}</p>

    </section>


    <!-- only visible on desktop, see ../Buero.vue -->
    <section v-show="!isMobile" class="about-section generic-two-col-grid-section two-col-grid-right">
      <article class="text-container">
        <h1 class="upper">{{ about.title }}</h1>
        <div class="about-text right-hand-content" v-html="about.text">
        </div>
      </article>
    </section>

  </div>

</template>

<script>
import TeamPair from "./team/TeamPair.vue"
import store, { isMobile, hideTitle } from "../../store"

export default {
  name: "TeamAbout",
  components: { TeamPair },
  props: {
    team: {
      type: Array,
      required: true
    },
    about: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTeamPair: 0,
      activeTeamSingle: 0
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
    teamPairsLength() {
      return Math.ceil(this.team.length / 2)
    },
    teamPairs() {
      // returns array of pairs of team members
      // if number of members is odd, last item is -1
      const len = this.team.length;
      const pairs = [];
      for (let i = 0; i < len; i += 2) {
        const newPair = [];
        newPair.push(this.team[i])
        if (i+1 < len) { // is there one more item?
          newPair.push(this.team[i+1])
        } else {
          // odd number of team members
          // last pair is incomplete
          newPair.push({empty: true})
        }
        pairs.push(newPair)
      }
      return pairs
    }
  },
  methods: {
    nextTeamPair() {
      const len = Math.ceil(this.team.length / 2);
      this.activeTeamPair = (this.activeTeamPair + 1) % len
    },
    nextTeamSingle() {
      const len = this.team.length;
      this.activeTeamSingle = (this.activeTeamSingle + 1) % len
    }
  }
}
</script>
