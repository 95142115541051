<template>

  <full-page ref="fullpage" :options="options" id="fullpage">
  <!-- this is not efficient, but i really have spent enough time optimizing stuff in this project -->

    <div v-for="project, key in projects" class="section">
      <Competition :project="project" class="competition" />
    </div>

  </full-page>

</template>

<script>
import Competition from "./project/Competition.vue"
import store, { isMobile, hideTitle } from "../store"

export default {
  name: "CompetitionList",
  components: { Competition },
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        licenseKey: '85DEFA44-76C84CD0-90A5D86C-92F9F88C',
        normalScrollElements: '.description-content, .nav-content, .project-list',
        anchors: null
      }
    }
  },
  created() {
    // hide page title on mobile
    if (isMobile()) {
      hideTitle()
    }

    // generate array of slugs for the project-sections
    // this array passed to the anchors property of
    // the fullpage.js options.
    this.options.anchors = this.projects.map((project) => {
      return project.slug
    })

    // generate array of background colors
    // (evenutally this is going to become obsolete
    // as there will be a list of colors to choose from
    // in the backend)
    /*
    const neededLength = this.projects.length;
    const hslArray= new Array(neededLength);
    for (let i = 0; i < hslArray.length; i++) {
      hslArray[i] = `hsl(${Math.floor(Math.random() * 360)} 80% 90%)`
    }

    console.log(hslArray)
    this.options.sectionsColor = [...hslArray]
    */
  }
}
</script>


